import { checkCaptcha, newsletterSubscribe } from "@/actions/newsletter";
import React, {
  RefObject,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface useNewsletterHookReturn {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  captchaToken: string;
  response: ResponseType | undefined;
  SubscribeAction: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  ReCAPTCHAWidget: () => JSX.Element;
}

interface ResponseType {
  status: number | boolean;
  message: string | undefined;
}

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

const useNewsletterHook = ({
  email,
  setEmail,
}: any): useNewsletterHookReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<ResponseType>();
  const [captchaToken, setCaptchaToken] = useState("");
  const [hasPassed, setHasPassed] = useState(false);

  const recaptcha: RefObject<typeof ReCAPTCHA> = useRef(null);

  const onCaptchaChange = async (token: string | null) => {
    if (token) {
      setCaptchaToken(token);

      // Need more security (Check server side)
      const checkToken = await checkCaptcha(token);

      if (checkToken) {
        setHasPassed(true);
      }
    }
  };

  useEffect(() => {
    setResponse(undefined);
  }, [email]);

  const ReCAPTCHAWidget = useMemo(() => {
    const CaptchaWidget = () => (
      <div className="my-4">
        {!captchaToken && SITE_KEY && (
          <ReCAPTCHA
            size="normal"
            sitekey={String(SITE_KEY)}
            onChange={onCaptchaChange}
            ref={recaptcha}
          />
        )}
      </div>
    );

    return CaptchaWidget;
  }, [captchaToken]);

  const SubscribeAction = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    setResponse(undefined);

    if (!hasPassed) {
      setIsLoading(false);
      setResponse({
        status: 500,
        message:
          "Vänligen verifiera att du inte är en robot genom att klicka i rutan.",
      });
      setCaptchaToken("");
      return;
    }

    try {
      const response = await newsletterSubscribe(email);

      setIsLoading(false);
      //setEmail("");
      setResponse({
        status: 200,
        message: response.message,
      });
    } catch (err: any) {
      setIsLoading(false);
      setResponse({
        status: 500,
        message: err.message,
      });
    }
  };

  return {
    email,
    setEmail,
    isLoading,
    response,
    captchaToken,
    SubscribeAction,
    ReCAPTCHAWidget,
  };
};

export default useNewsletterHook;
