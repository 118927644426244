"use client"; // Error components must be Client Components

import Button from "@/components/Elements/Button";
import Link from "next/link";

export default function Maintenance() {
  return (
    <div className="bg-blue text-white h-screen flex justify-center items-center">
      <div className="flex flex-col text-white text-center">
        <h2 className="font-bold mb-2 text-4xl">Underhåll</h2>

        <p className="text-white">
          Vi genomgår för närvarande underhåll. Var god försök igen senare.
        </p>

        <div className="w-full flex flex-row justify-center flex-wrap space-x-5 mt-5">
          <Link href="/" className="!no-underline">
            <Button borderColor="white">Gå till startsidan</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
