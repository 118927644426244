"use client";

import Image from "next/image";
import Link from "next/link";
import { Key } from "react";

interface SocialIconsProps {
  links: any;
}

const SocialIcons: React.FC<SocialIconsProps> = ({ links }) => {
  if (!links) return null;

  return (
    <ul className="flex list-none p-0 m-0 space-x-4 transition-all duration-1000">
      {links.map((link: any, index: Key) => {
        return (
          <li key={index}>
            <Link href={link.url} target="_blank" className="hover:opacity-80">
              <Image src={link.icon} alt={link.title} width={24} height={24} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialIcons;
