"use client";

import React, { useEffect, useState } from "react";
import MenuWalker from "@/components/Header/Menu/MenuWalker";
import Link from "next/link";
import Image from "next/image";
import HeaderPanel from "./HeaderPanel";
import {
  GoogleTranslateInit,
  GoogleTranslateMenuWidget,
} from "../Plugins/GoogleTranslate";
import ReactHtmlParser from "html-react-parser";
import { useShallow } from "zustand/react/shallow";
import useUserStore from "@/stores/useUserStore";
import toast from "react-hot-toast";
import { signOut } from "next-auth/react";
import useModalStore from "@/hooks/useModalStore";
import classNames from "classnames";

interface HeaderProps {
  headerProps: any;
}

const Header: React.FC<HeaderProps> = ({ headerProps }) => {
  const { themeSettings, menuProps } = headerProps;

  const { user, userInfo, clearSession } = useUserStore(
    useShallow((state) => state)
  );

  const logo = themeSettings?.logo?.sourceUrl;

  const { loginModal } = useModalStore();
  const { notificationBarText, notificationBarVisible } = themeSettings || {};
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logout = () => {
    toast.loading("Loggar ut...", {
      id: "logout-user",
    });
    clearSession();
    signOut();
  };

  const openLoginModal = () => {
    if (!loginModal) return;

    loginModal.setTitle("Logga in på Tovek.se");
    loginModal.onOpen();
  };

  return (
    <>
      {/* {notificationBarVisible && (
        <HeaderNotificationBar notificationBarText={notificationBarText} />
      )} */}

      <div
        className={classNames("bg-yellow py-2 md:py-3 border-b border-black", {
          "hidden md:block": !notificationBarVisible || !notificationBarText,
        })}
      >
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-12 justify-between">
            <div className="text-black flex items-center space-x-4 [&>p]:!text-xs md:[&>p]:!text-sm md:col-span-8">
              {notificationBarText && notificationBarVisible && (
                <>
                  <svg
                    width={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 1V2.5L2 6V5H0V11H2V10L4.11255 10.6162C4.0391 10.8985 4 11.1947 4 11.5C4 13.433 5.567 15 7.5 15C9.05764 15 10.3776 13.9825 10.8315 12.5759L14 13.5V15H16V1H14ZM6.0349 11.1768L8.90919 12.0152C8.69905 12.5898 8.14742 13 7.5 13C6.67157 13 6 12.3284 6 11.5C6 11.3891 6.01204 11.2809 6.0349 11.1768Z"
                      fill="#000000"
                    />
                  </svg>
                  {ReactHtmlParser(notificationBarText)}
                </>
              )}
            </div>
            <div className="btn space-x-4 hidden md:block md:col-span-4 ml-auto text-left md:text-right">
              {!user?.id ? (
                <span className="flex flex-row md:space-x-1 lg:space-x-2">
                  <Link href="/mina-sidor/?skapa-konto">Skapa ett konto</Link>
                  <span className="opacity-30">|</span>
                  <span
                    onClick={() => openLoginModal()}
                    className="cursor-pointer underline"
                  >
                    Logga in
                  </span>
                </span>
              ) : (
                <span className="flex flex-row md:space-x-1 lg:space-x-2 whitespace-nowrap">
                  <span>
                    <span className="hidden sm:inline-block font-semibold">
                      Inloggad som:
                    </span>{" "}
                    {userInfo?.username}{" "}
                    {/* <span className="font-bold opacity-50 inline">
                      {refUsername ? `(via ${refUsername})` : ""}
                    </span> */}
                  </span>
                  <span className="opacity-30">|</span>
                  <Link href="/mina-sidor">Mina sidor</Link>
                  <span className="opacity-30">|</span>
                  <span
                    onClick={() => logout()}
                    className="cursor-pointer underline"
                  >
                    Logga ut
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="sticky-scroll-container"
        className="absolute h-[100px] w-[1px]"
      ></div>

      <nav
        id="header-nav"
        className="header-nav sticky group bg-blue border-neutral-200 -top-1 z-[990]"
      >
        <div className="container">
          <div className="flex flex-row items-center justify-between mx-auto py-4 lg:py-0">
            <Link
              href="/"
              id="header-logo"
              className="flex relative w-[109px] h-[40px] md:h-[56px] md:w-[153px] md:group-[.is-pinned]:!w-[120px] "
            >
              {logo && (
                <Image
                  src={logo}
                  priority
                  alt="logo"
                  fill
                  placeholder="empty"
                  className="w-full max-w-[183px]"
                />
              )}
            </Link>

            <GoogleTranslateInit />

            <div className="flex flex-row items-center justify-end">
              <MenuWalker
                menuProps={menuProps}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                wrapper={(children: React.ReactElement[]) => (
                  <ul
                    id="nav-menu"
                    className="invisible lg:visible pt-6 md:pt-0 bg-blue mt-0 lg:bg-none w-full h-0  p-0 m-0 list-none lg:h-auto lg:w-auto absolute lg:relative left-0 lg:left-auto top-[100%] lg:flex lg:mx-auto lg:items-center lg:space-x-4 transition-[height] duration-500 font-medium flex-row items-start justify-start lg:p-0 lg:flex-row lg:mt-0 lg:border-0 z-40"
                  >
                    <div className="container lg:max-w-none flex flex-col lg:flex-row">
                      {children}

                      <div className="block lg:hidden">
                        <GoogleTranslateMenuWidget />
                      </div>
                    </div>
                  </ul>
                )}
              />
              <HeaderPanel
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                isMobileMenuOpen={isMobileMenuOpen}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

Header.displayName = "Header";

export default Header;
