import { getThemeSettings } from '@/queries/theme';
import React from 'react'
import useSWR from 'swr';

const useGetThemeSettings = () => {
    const { data: settings, isLoading, error } = useSWR(
        ["auctions/getThemeSettings"],
        async () => {
            const themeSettings = await getThemeSettings();
            return themeSettings;
        },
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshInterval: 10000,
        }
    );

    return { settings, isLoading, error } as any;
}

export default useGetThemeSettings