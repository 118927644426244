// useServerTime.tsx
"use client";

import { useEffect, useState } from "react";
import useServerTimeStore from "@/stores/useServerTimeStore";
import { getSwedishDate } from "@/lib/utils";

const useServerTime = () => {
    const { initialServerTime, getServerTime } = useServerTimeStore((state) => state);
    const [currentTime, setCurrentTime] = useState<Date | null>(
        initialServerTime ? getServerTime() : null
    );

    useEffect(() => {
        if (!initialServerTime) return;
        const interval = setInterval(() => {
            const newTime = getServerTime();
            if (!currentTime || (newTime && newTime.getSeconds() !== currentTime.getSeconds())) {
                setCurrentTime(newTime);
            }
        }, 200);
        return () => clearInterval(interval);
    }, [initialServerTime, getServerTime, currentTime]);

    return initialServerTime && currentTime ? getSwedishDate(currentTime) : false;
};

export default useServerTime;