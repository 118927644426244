"use client";

import Input from "@/components/Elements/Input";
import { GoogleTranslateSelect } from "@/components/Plugins/GoogleTranslate";
import Image from "next/image";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Link from "next/link";
import useNotificationStore from "@/stores/useNotificationStore";
import useAccountMessagesStore from "@/stores/useAccountMessagesStore";
import { useShallow } from "zustand/react/shallow";
import useUserStore from "@/stores/useUserStore";

interface HeaderProps {
  pageTitle?: string;
  menuProps?: any;
  setIsMobileMenuOpen: any;
  isMobileMenuOpen: boolean;
}

const mobileViewportMin = 1224;

const HeaderPanel: React.FC<HeaderProps> = ({
  pageTitle,
  menuProps,
  setIsMobileMenuOpen,
  isMobileMenuOpen,
}) => {
  const { notificationData } = useNotificationStore();
  const { accountMessages } = useAccountMessagesStore();
  const { user } = useUserStore(useShallow((state) => state));
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const searchRef = React.createRef<HTMLInputElement>();
  const notificationUnreadCount = notificationData?.unreadCount;
  const accountMessagesCount = accountMessages?.length || 0;

  const handleMobileMenu = () => {
    setIsMobileMenuOpen((prev: any) => !prev);
  };

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleLanguageClick = () => {
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!searchInput.replace(/\s/g, "").length) {
      return;
    }

    window.location.href = `/sok/?s=${searchInput}`;
  };

  // Detect when sticky is reached and add class
  useEffect(() => {
    const el = document.querySelector(".header-nav");
    const scrollContainer = document.getElementById("sticky-scroll-container");

    if (!el || !scrollContainer) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          el.classList.add("is-pinned", "shadow-lg");
        } else {
          el.classList.remove("is-pinned", "shadow-lg");
        }
      },
      {
        threshold: [1],
      }
    );

    observer.observe(scrollContainer);

    return () => {
      observer.unobserve(scrollContainer);
    };
  }, []);

  const resizeActions = () => {
    const headerLogo = document.getElementById("header-logo");

    if (!headerLogo) return;

    if (window.innerWidth < mobileViewportMin) {
      if (isSearchOpen) {
        headerLogo?.setAttribute("style", "visibility: hidden;");
      } else {
        headerLogo?.setAttribute("style", "visibility: visible;");
      }
    } else {
      if (isSearchOpen) {
        headerLogo?.setAttribute("style", "visibility: visible;");
      }
      setIsMobileMenuOpen(false);
    }
  };

  // Resize & mobile actions
  useLayoutEffect(() => {
    window.addEventListener("resize", resizeActions);

    return () => {
      window.removeEventListener("resize", resizeActions);
    };
  }, [isSearchOpen, isMobileMenuOpen]);

  // Search is open actions
  useLayoutEffect(() => {
    const navbar = document.getElementById("main-navbar");

    if (isSearchOpen) {
      navbar?.setAttribute("style", "display: none;");
      searchRef.current?.focus();
      resizeActions();
    } else {
      navbar?.setAttribute("style", "display: flex;");
      resizeActions();
    }
  }, [isSearchOpen]);

  // Mobile menu is open actions
  useLayoutEffect(() => {
    const navMenu = document.getElementById("nav-menu");

    if (isMobileMenuOpen) {
      navMenu?.classList.remove("invisible");
      navMenu?.style.setProperty("height", "100dvh");
    } else {
      navMenu?.classList.add("invisible");
      navMenu?.style.removeProperty("height");
    }
  }, [isMobileMenuOpen]);

  return (
    <nav className="border-neutral-200">
      <div className="md:container flex flex-wrap items-center justify-between mx-auto">
        <div className="flex flex-row justify-start space-x-6 sm:space-x-6 items-center">
          <div className="flex flex-row items-start relative justify-start transition-all duration-500">
            <form
              className="w-full absolute top-[50%] translate-y-[-50%] right-[40px] z-[9000]"
              style={{ direction: "rtl" }}
              onSubmit={handleSearch}
            >
              <Input
                type="text"
                visible={isSearchOpen}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Sök..."
                ref={searchRef}
                style={{
                  width: !isSearchOpen ? "0px" : "50vw",
                  direction: "ltr",
                }}
              />
            </form>

            {!isSearchOpen ? (
              <Image
                width="10"
                height="10"
                className="relative w-6 h-6 cursor-pointer hover:opacity-80"
                alt=""
                src="/icons/search.svg"
                onClick={handleSearchClick}
                priority
              />
            ) : (
              <Image
                width="10"
                height="10"
                className="relative w-6 h-6 cursor-pointer hover:opacity-80"
                alt=""
                src="/icons/close.svg"
                onClick={handleSearchClick}
                priority
              />
            )}
          </div>

          <Link
            className="lg:flex flex-row items-start justify-start relative"
            href="/mina-sidor/"
          >
            <Image
              width="10"
              height="10"
              className="relative w-6 h-6 cursor-pointer hover:opacity-80"
              alt=""
              src="/icons/person.svg"
              priority
            />

            {accountMessagesCount > 0 && (
              <div className="absolute top-[12px] -right-[18px] w-[18px] h-[18px] bg-yellow text-blue font-bold text-xs rounded-full flex items-center justify-center">
                {accountMessagesCount}
              </div>
            )}
          </Link>

          {hasMounted && user && (
            <Link
              className="hidden lg:flex flex-row items-start justify-start relative"
              href="/mina-sidor/"
            >
              <Image
                width="10"
                height="10"
                className="relative w-6 h-6 cursor-pointer hover:opacity-80"
                alt="Notiser"
                src={
                  notificationUnreadCount
                    ? "/icons/bell.svg"
                    : "/icons/bell-empty.svg"
                }
                priority
              />

              {notificationUnreadCount > 0 && (
                <div className="absolute top-[12px] -right-[18px] w-[18px] h-[18px] bg-yellow text-blue font-bold text-xs rounded-full flex items-center justify-center">
                  {notificationUnreadCount}
                </div>
              )}
            </Link>
          )}

          {hasMounted && !user && (
            <div
              className="hidden lg:flex flex-row items-start justify-start"
              onClick={handleLanguageClick}
            >
              <div className="relative hover:opacity-80 cursor-pointer">
                <Image
                  width="10"
                  height="10"
                  className="relative w-6 h-6 cursor-pointer hover:opacity-80"
                  alt=""
                  src="/icons/language.svg"
                  priority
                />
                <div className="absolute bottom-0 w-full h-full">
                  <GoogleTranslateSelect invisible />
                </div>
              </div>
            </div>
          )}

          <div className="flex lg:hidden flex-row items-start justify-start">
            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center text-sm text-white rounded-lg lg:hidden hover:bg-white-100 focus:outline-none"
              aria-controls="navbar-default"
              aria-expanded="false"
              onClick={handleMobileMenu}
            >
              <span className="sr-only">
                {!isMobileMenuOpen ? "Öppna" : "Stäng"} hamburgarmeny
              </span>

              {!isMobileMenuOpen ? (
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderPanel;
