import classNames from "classnames";
import Link from "next/link";
import React from "react";

const SubMenuWalker: React.FC<{
  item: any;
  depth: number;
  isOpen?: any;
}> = ({ item, depth, isOpen }) => {
  const subLiClass = classNames("relative");

  const subMenuNavLinkClass = classNames(
    "relative block !no-underline py-3 px-6 md:px-2 text-white bg-blue-700 rounded break-words md:bg-transparent md:font-semibold md:text-sm xl:text-base"
  );

  const submenuClass = classNames(
    `submenu invisible w-auto list-none p-0 m-0 md:ml-0 max-h-0 md:max-h-[300px] md:overflow-hidden relative md:absolute md:h-0
            transition-[height] duration-300 ease-in-out origin-top-left
            min-w-32 w-100 depth-${depth}`,
    { "left-[100%]": depth > 0 },
    { "top-0": depth >= 1 },
    { "top-[100%]": depth === 0 },
    { open: isOpen }
  );

  return (
    <ul className={submenuClass}>
      {item.children.map((childItem: any) => {
        const hasChildren = childItem.children?.length > 0;

        return (
          <li key={childItem.id} className={`${subLiClass} depth-${depth}`}>
            <Link href={childItem.uri} className={subMenuNavLinkClass}>
              {childItem.label}
            </Link>
            {hasChildren && (
              <SubMenuWalker
                item={childItem}
                depth={depth + 1}
                isOpen={isOpen}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default SubMenuWalker;
