"use client";

import useNewsletterHook from "@/hooks/useNewsletterHook";
import Alert from "@/components/Elements/Alert";
import Button from "@/components/Elements/Button";
import Input from "@/components/Elements/Input";
import React, { useState } from "react";
import useGetThemeSettings from "@/hooks/useGetThemeSettings";
import Link from "next/link";
import classNames from "classnames";

const SubscribeWidget = () => {
  const [email, setEmail] = useState<string>("");

  const {
    isLoading,
    response,
    captchaToken,
    SubscribeAction,
    ReCAPTCHAWidget,
  } = useNewsletterHook({
    email,
    setEmail,
  });
  const { settings } = useGetThemeSettings();

  const documentgrouppolicy =
    settings?.documents?.documentgrouppolicy?.mediaItemUrl;

  const [showCaptcha, setShowCaptcha] = React.useState(false);

  const validateEmail = (email: string) => {
    if (!email) return true;

    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const emailSubmitted = response?.status === 200;

  return (
    <div className="flex flex-col w-full md:w-auto">
      {!emailSubmitted && (
        <div className="flex flex-col space-y-4">
          <form
            className="flex flex-col lg:flex-row w-full gap-[12px]"
            onSubmit={SubscribeAction}
          >
            <Input
              placeholder="Din e-postadress"
              inputClassName="!h-full !min-h-[50px]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!validateEmail(email) ? true : undefined}
              isLoading={isLoading}
              onBlur={() => setShowCaptcha(true)}
            />
            <Button
              type="submit"
              size="md"
              bgColor="yellow"
              textColor="blue"
              className="!self-stretch h-auto w-fit"
              disabled={!email || !captchaToken || !validateEmail(email)}
            >
              Prenumerera
            </Button>
          </form>

          <div className="relative text-sm leading-[150%] text-white inline-block max-w-[310px] md:max-w-none mt-3 md:mt-0">
            Genom att prenumerera godkänner du{" "}
            <span className="[text-decoration:underline]">
              <Link href={documentgrouppolicy || "#"} target="_blank">
                personuppgiftspolicyn
              </Link>
            </span>{" "}
            för Toveks Auktioner AB.
          </div>
        </div>
      )}

      {showCaptcha && !emailSubmitted && <ReCAPTCHAWidget />}

      {response && response.status && (
        <div className="w-full">
          <div className="flex flex-col items-end justify-start">
            {response?.status && (
              <div
                className={classNames(
                  "relative block w-full text-base font-regular px-4 py-4 rounded-lg flex w-full",
                  {
                    "bg-green-500 text-white": response.status === 200,
                    "bg-amber-500 text-black": response.status !== 200,
                  }
                )}
                color={response.status === 200 ? "green" : "amber"}
              >
                <span className="font-semibold">{response.message}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscribeWidget;
