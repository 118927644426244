"use client";

import { useState, useEffect } from "react";
import useServerTime from "@/hooks/useServerTime";

const ServerTimeDisplay = () => {
  const [time, setTime] = useState<string | null>(null);
  const serverTime = useServerTime();

  useEffect(() => {
    if (!serverTime) return;

    setTime(serverTime);
  }, [serverTime]);

  if (time === null) {
    return null;
  }

  return (
    <span className="font-light opacity-80 whitespace-nowrap text-xs">
      Tiden för auktioner är: {time}
    </span>
  );
};

export default ServerTimeDisplay;
