"use client";

import React, { useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { JSXElementConstructor, ReactElement, Suspense } from "react";
import SubMenuWalker from "./SubMenuWalker";
import Image from "next/image";
import useIsMobile from "@/hooks/useIsMobile";

const SubMenuWalkerLazy: React.FC<SubMenuWalkerProps> = ({
  childItem,
  depth,
  isOpen,
}) => (
  <Suspense fallback={<div>Laddar meny...</div>}>
    <SubMenuWalker item={childItem} depth={depth} isOpen={isOpen} />
  </Suspense>
);

const MenuWalker: React.FC<MenuWalkerProps> = React.memo(
  ({
    wrapper: Wrapper,
    menuProps,
    setIsMobileMenuOpen,
    linkClass = undefined,
  }): ReactElement<any, string | JSXElementConstructor<any>> | null => {
    const [subMenuIsOpen, setSubMenuIsOpen] = useState<any>([]);

    const { menuItems } = menuProps;
    const isMobile = useIsMobile();

    if (!menuItems || menuItems.length === 0) {
      return null;
    }

    const mainNavLinkClass = classNames({
      "relative flex items-center py-3 lg:py-8 px-0 lg:px-6 font-open-sans !no-underline text-white bg-blue-700 font-bold md:font-semibold md:text-16 xl:text-18 font-open-sans rounded md:bg-transparent":
        !linkClass,
    });

    const menu = menuItems.map((item: any, index: number) => {
      const hasChildren = item.children?.length > 0;
      const isOpen =
        subMenuIsOpen.findIndex((i: number) => i === item.id) !== -1;

      const linkClass = classNames({
        [mainNavLinkClass]: true,
        active: isOpen,
        "flex justify-between items-center md:space-x-3 has-submenu":
          hasChildren,
      });

      const menuIcon = item.link_acf?.icon;
      const cssClasses = item?.cssClasses.join(" ");

      const mainLiClass = classNames(
        "relative mb-0 md:group-[.is-pinned]:!p-2",
        {
          [cssClasses]: cssClasses !== "",
        }
      );

      const linkAction = (e: React.MouseEvent) => {
        if (isMobile && hasChildren) {
          e.preventDefault();
          setSubMenuIsOpen((prev: any) => {
            if (prev.includes(item.id)) {
              return prev.filter((i: number) => i !== item.id);
            } else {
              return [...prev, item.id];
            }
          });
        } else if (setIsMobileMenuOpen) {
          setIsMobileMenuOpen(false);
        }
      };

      return (
        <li key={item.id} className={mainLiClass}>
          <Link href={item.uri} className={linkClass} onClick={linkAction}>
            {menuIcon && (
              <Image
                width="24"
                height="24"
                alt={menuIcon.altText}
                src={menuIcon.sourceUrl}
                className="inline-block mr-3"
              />
            )}

            <span>{item.label}</span>

            {hasChildren && (
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="transition-all duration-150"
              >
                <path
                  d="M10.59 0.589966L6 5.16997L1.41 0.589966L0 1.99997L6 7.99997L12 1.99997L10.59 0.589966Z"
                  fill="white"
                />
              </svg>
            )}
          </Link>

          {hasChildren && (
            <SubMenuWalkerLazy childItem={item} depth={0} isOpen={isOpen} />
          )}
        </li>
      );
    });

    return Wrapper ? (
      Wrapper(menu) || null
    ) : (
      <ul className="list-none  p-0 m-0 ">{menu}</ul>
    );
  }
);

MenuWalker.displayName = "MenuWalker";

export default MenuWalker;
