import { create } from "zustand";
import { produce } from "immer";

interface NotificationStoreProps {
    notificationData: any;
    clearNotifications: () => void;
    setNotificationData: (notifications: any) => void;
}

const useNotificationStore = create<NotificationStoreProps>((set, get) => ({
    notificationData: [],
    setNotificationData: (notificationData: any) =>
        set(
            produce((state: any) => {
                state.notificationData = notificationData
            })
        ),
    clearNotifications: () => set({ notificationData: [] })
})
)

export default useNotificationStore;